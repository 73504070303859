<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>广告屏</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div>
                <VyAddBtn @click.native="openAdd" />
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="title" align="center" label="标题"> </el-table-column>
                <el-table-column prop="image" align="center" label="图片">
                    <template slot-scope="{ row }">
                        <img :src="row.image" style="width: 100%" />
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="btns">
                            <div class="_operate">
                                <button @click="openSet(row)">编辑</button>
                            </div>
                            <div class="_delete">
                                <button @click="del(row)">删除</button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 添加或编辑 -->
        <el-dialog
            :title="`${form.id ? '编辑' : '添加'}广告屏优势`"
            :visible.sync="dialogShow"
            width="50%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form" label-width="80px">
                <el-form-item label="标题">
                    <el-input v-model.trim="form.title"></el-input>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input type="textarea" autosize resize="none" v-model.trim="form.describe"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <upload-img :src="form.image" @removeSrc="() => (form.image = '')" ref="image"></upload-img>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
import uploadImg from "@/components/customs/uploadImg.vue";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";
export default {
    components: { uploadImg, VyAddBtn },
    data() {
        return {
            tableData: [],
            form: {},
            dialogShow: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
    },

    methods: {
        async getData() {
            let { data } = await axios.get("/api/marketing/adlist");
            return data;
        },
        // 打开添加
        openAdd() {
            this.form = {
                title: "",
                image: "",
                describe: "",
            };
            this.dialogShow = true;
        },
        // 打开编辑
        openSet(row) {
            this.form = { ...row };
            this.dialogShow = true;
        },
        // 确认
        async yes() {
            let { form } = this;
            form.image = this.$refs.image.url;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/marketing/adedit", QueryString.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/marketing/adadd", QueryString.stringify(form));
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
                return;
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
            this.dialogShow = false;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => true);
            if (!s) return;
            let { data } = await axios.delete("/api/marketing/addel", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
</style>
